<template>
  <v-data-table :headers="headers" :items="tanks" :search="search">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("Tanks") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Tank") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black">
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-beaker</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <v-form v-model="valid" ref="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      v-model="editedItem.custom_name"
                      :label="$t('Name')"
                      :counter="30"
                      :rules="rules"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          dense
                          v-model.number="editedItem.product_id"
                          :label="$t('Product')"
                          :items="products"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          v-model="editedItem.price"
                          :label="$t('Price')"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                          dense
                          v-model.number="editedItem.probe_id"
                          :label="$t('Probe')"
                          :items="probes"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                        ></v-select>
                  </v-col>
                  <v-col cols="12" >  
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          v-model.number="editedItem.capacity"
                          :label="$t('Capacity')"
                          :suffix="$t('litres')"
                          required
                          outlined
                        ></v-text-field>
                        </v-col>
                      <v-col cols="12" md="6" >
                        <v-text-field
                          dense
                          v-model.number="editedItem.diameter"
                          :label="$t('Height from bottom to sensor')"
                          suffix="cm"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                 
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          v-model.number="editedItem.water_offset"
                          :label="$t('Water offset')"
                          type="number"
                          suffix="cm"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          v-model.number="editedItem.fuel_offset"
                          :label="$t('Fuel offset')"
                          type="number"
                          suffix="cm"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          dense
                          v-model.number="editedItem.min_level"
                          :label="$t('Block min level')"
                          :suffix="$t('litres')"
                          required
                          outlined
                          :hint="$t('0 -> No limit')"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          dense
                          v-model.number="editedItem.max_level"
                          :label="$t('Block max level ')"
                          :counter="8"
                          :suffix="$t('litres')"
                          required
                          outlined
                          :hint="$t('0 -> No limit')"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="editedItem.status"
                          :label="$t('Active')"
                          color="indigo darken-3"
                          :true-value="2"
                          :false-value="1"
                          
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="!valid"> {{$t('Save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
            >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.TIME="{ item }">
        {{new Date(item.TIME).toLocaleString()}}
    </template>
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 2"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "tanks" ,

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      tanks: [],
      products: [],
      probes: [],
      valid: false,
      loading: false,
      loading2: false,
      rules: [
        v => v !== ''||  this.$t('Item is required'),
        v => v.length <= 30 || this.$t('A maximum  characters exceeded')
      ],
      editedItem: {
        id: null,
        custom_name: '',
        status: null,
        product: '',
        capacity: null,
        probe_name: '',
        water_offset: null,
        fuel_offset: null,
        volume: null,
        fuel_height: null,
        water_height: null,
        timestamp: null,
        min_level: null,
        max_level: null,
        block_max_level: 0,
        block_min_level: 0,
        probe: null
        
      
      },
      defaultItem: {
        id: null,
        custom_name: '',
        status: null,
        product: '',
        capacity: null,
        probe_name: '',
        water_offset: null,
        fuel_offset: null,
        volume: null,
        fuel_height: null,
        water_height: null,
        timestamp: null,

        min_level: null,
        max_level: null,
        block_max_level: 0,
        block_min_level: 0
      },
      editedIndex: -1,
      search: '',
      headers: [
        {
          text: this.$t('ID'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('Custom Name'),
          align: 'center',
          sortable: true,
          value: 'custom_name',
        },
        {
          text: this.$t('Product'),
          align: 'center',
          sortable: true,
          value: 'product',
        },
        {
          text: this.$t('capacity'),
          align: 'center',
          sortable: true,
          value: 'capacity',
        },
        {
          text: this.$t('Probe'),
          align: 'center',
          sortable: true,
          value: 'probe',
        },
        {
          text: this.$t('Water Offset'),
          align: 'center',
          sortable: true,
          value: 'water_offset',
        },
        {
          text: this.$t('Fuel offset'),
          align: 'center',
          sortable: true,
          value: 'fuel_offset',
        },
        {
          text: this.$t('Volume'),
          align: 'center',
          sortable: true,
          value: 'volume',
        },
        {
          text: this.$t('Fuel Height'),
          align: 'center',
          sortable: true,
          value: 'fheight',
        },
        {
          text: this.$t('Water Height'),
          align: 'center',
          sortable: true,
          value: 'wheight',
        },
        {
          text: this.$t('Last record'),
          align: 'center',
          sortable: true,
          value: 'TIME',
        },
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('Actions'),
          align: 'center',
          value: 'actions',
        },
      
      
      ]
        
    }
  },
  methods: {
      async initialize () {
       try {
          this.loading = true
          const tnk = await this.$http.get("/tank")
          this.tanks = tnk.data
          const prbs = await this.$http.get("/probe")
          this.probes = prbs.data
          const prods = await this.$http.get("/product")
          this.products = prods.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
    },
    
    editItem (item) {
      this.editedIndex = this.tanks.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.tanks.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = null
      try {
        res = await this.$http.delete(`/tank/${this.editedItem.id}`)
        this.tanks.splice(this.editedIndex, 1)
        this.closeDelete()
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        this.closeDelete()
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          const data = this.prepareData(this.editedItem)
          res = await this.$http.put("/tank", data.tank)
          if (data.probe.id) {
            res = await this.$http.put("/probe", data.probe)
          }
          if (data.product.id) {
            res = await this.$http.put("/product", data.product) 
          }
          Object.assign(this.tanks[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      } else {
        try {
          this.loading2 = true
          const data = this.prepareData(this.editedItem)
          res = await this.$http.post("/tank", data.tank)
          if (data.probe.id) {
            res = await this.$http.put("/probe", data.probe)
          }
          if (data.product.id) {
            res = await this.$http.put("/product", data.product) 
          }
          this.tanks.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
      await this.initialize()
    },
    getProbe(id) {
         return this.probes.find(v => v.id === id)?.name ?? 'N/A'
    },
    prepareData() {
      let probe = this.probes.filter(v => v.id === this.editedItem.probe_id)[0]
      probe  = { ...probe,  ...{ id: this.editedItem.probe_id,
                  tank_id: parseInt(this.editedItem.id),
                  diameter: this.editedItem.diameter,
                  calib_end_level: this.editedItem.min_level,
                  capacity: this.editedItem.max_level === 0 ? 1000000 : this.editedItem.max_level,
                  fuel_offset: this.editedItem.fuel_offset,
                  water_offset: this.editedItem.water_offset }
      }
      let product = this.products.filter(v => v.id === this.editedItem.product_id)[0]
      product = {...product, ...{id: this.editedItem.product_id, price: this.editedItem.price}}
      return {
        tank: {
          id: this.editedItem.id,
          name: this.editedItem?.name ?? `Tank_${this.tanks.length+1}`,
          custom_name: this.editedItem.custom_name,
          capacity: this.editedItem.capacity,
          product_id: this.editedItem.product_id,
          status: this.editedItem.status
        },
        product,
        probe
      }

    }
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add New Tank') : this.$t('Edit Tank')
      },
      computedHex() {
        return   parseInt(this.editedItem.probe_num).toString(16)  ||  0
      },
      
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

